import React from 'react';
import About from '../About/About';

const Main = () => {
  return (
    <main>
      <About />
    </main>
  );
};

export default Main;
